import { getItem } from "../helpers/function";

const Menu = [
  {
    path: "/dashboard",
    icon: "fa fa-th",
    title: "Dashboard",
    is_show: true,
  },

  {
    path: "#",
    icon: "fa fa-hdd",
    title: "Data Master",
    is_show: false,
    children: [
      { path: "/master-group", title: "Master Group", is_show: false, },
      { path: "/master-jenis", title: "Master Jenis", is_show: false, },
      { path: "/master-gudang", title: "Master Gudang", is_show: false, },
      { path: "/master-baki", title: "Master Baki", is_show: false, },
      { path: "/master-bandrol", title: "Master Bandrol", is_show: false, },
      // { path: '#', title: 'Master Pelanggan Non Member' },
      { path: "/master-sales", title: "Master Sales", is_show: false, },
      { path: "/master-marketplace", title: "Master Marketplace", is_show: false, },
      { path: '/master-tukang', title: 'Master Tukang', is_show: false, },
      { path: "/master-kode-transaksi", title: "Master Kode Transaksi", is_show: false, },
      { path: "/master-kondisi-barang", title: "Master Kondisi Barang", is_show: false, },
      { path: "/master-kondisi-pembelian", title: "Master Kondisi Pembelian", is_show: false, },
      { path: "/master-bank", title: "Master Bank", is_show: false, },
      { path: "/master-rekening", title: "Master Rekening", is_show: false, },
      { path: "/master-data-hadiah", title: "Master Hadiah", is_show: false, },
      { path: "/master-parameter-point", title: "Parameter Point", is_show: false, },
      { path: "/master-parameter-harga-emas", title: "Parameter Harga Emas", is_show: false, },
      // {
      //   path: "#",
      //   title: "Master Berlian",
      //   type: "BERLIAN_MODULE",
      //   children: [
      //     { path: "/master-batu-shape", title: "Batu Shape" },
      //     { path: "/master-batu-color", title: "Batu Color" },
      //     { path: "/master-batu-clarity", title: "Batu Clarity" },
      //     { path: "/master-batu-fluorencence", title: "Batu Fluorencence" },
      //     { path: "/master-batu-symetry", title: "Batu Symetry" },
      //     { path: "/master-batu-polish", title: "Batu Polish" },
      //     { path: "/master-batu-cutgrade", title: "Batu Cutgrade" },
      //     //  { path: "/master-batu-berlian", title: "Batu Berlian" },
      //     { path: "/jenis-batu", title: "Jenis Batu" },
      //   ],
      // },
      { path: '/master-parameter-rugi-nota', title: 'Parameter Rugi Nota', is_show: false, },
    ],
  },
  {
    path: "#",
    icon: "fa fa-filter",
    title: "Sepuhan",
    type: "SEPUHAN_MODULE",
    is_show: false,
    children: [
      {
        path: "/sortir-pembelian",
        title: "Sortir Pembelian",
        type: "SEPUHAN_MODULE",
        is_show: false,
      },
    ],
  },

  {
    path: "#",
    icon: "fa fa-archive",
    title: "Barang",
    is_show: false,
    children: [
      {
        path: "/penerimaan-barang",
        title: "Penerimaan Barang",
        type: "SEMI_GROSIR_MODULE",
        is_show: false,
      },
      { path: "/tambah-data-barang", title: "Tambah Data Barang", is_show: false, },
      { path: "/data-barang", title: "Data Barang", is_show: false, },
      // { path: "/terima-barang", title: "Terima Barang" },
      { path: "/hancur-data-barang", title: "Hancur Data Barang", is_show: false, },
      { path: "/pindah-barang", title: "Pindah Barang", is_show: false, },
      { path: "/pindah-barang-satu-baki", title: "Pindah Barang Satu Baki", is_show: false, },
      { path: "/timbang-baki", title: "Timbang Baki", is_show: false, },
    ],
  },
  {
    path: "#",
    icon: "fa fa-shopping-cart",
    title: "Penjualan",
    is_show: false,
    children: [
      { path: "/transaksi-penjualan", title: "Transaksi Penjualan", is_show: false, },
      { path: "/lihat-penjualan", title: "Lihat Penjualan", is_show: false, },
      { path: "/batal-penjualan", title: "Batal Penjualan", is_show: false, },
      { path: "/lihat-titipan", title: "Lihat Titipan, is_show:false, " },
    ],
  },
  {
    path: "#",
    icon: "fa fa-boxes",
    title: "Pesanan",
    is_show: false,
    children: [
      { path: "/transaksi-pesanan", title: "Transaksi Pesanan", is_show: false, },
      { path: "/lihat-pesanan", title: "Lihat Pesanan", is_show: false, },
    ],
  },

  {
    path: "#",
    icon: "fa fa-shopping-bag",
    title: "Pembelian",
    is_show: false,
    children: [
      { path: "/transaksi-pembelian", title: "Transaksi Pembelian", is_show: false, },
      { path: "/lihat-pembelian", title: "Lihat Pembelian", is_show: false, },
    ],
  },
  {
    path: "/import-data",
    icon: "fa fa-upload",
    title: "Import Data",
    is_show: false,
  },
  {
    path: "#",
    icon: 'ion ion-md-build fa-2x fa-fw pull-left m-r-10 text-black-lighter"',
    title: "Service",
    is_show: false,
    children: [
      { path: "/transaksi-services", title: "Transaksi Service", is_show: false, },
      { path: "/lihat-services", title: "Lihat Services", is_show: false, },
    ],
  },

  {
    path: "#",
    icon: "fa fa-hand-holding-usd",
    title: "Hutang",
    is_show: false,
    children: [
      { path: "/transaksi-hutang", title: "Transaksi Hutang", is_show: false, },
      { path: "/lihat-hutang", title: "Lihat Hutang", is_show: false, },
    ],
  },

  {
    path: "/uang-kas",
    icon: "fas fa-money-bill-alt",
    title: "Uang Kas",
    is_show: false,
    // children: [
    // { path: "/lihat-uang-kas", title: "Uang Kas" },
    // { path: "/tambah-uang-kas", title: "Tambah Uang Kas" },
    // { path: "/ambil-uang-kas", title: "Ambil Uang Kas" },
    // ],
  },
  {
    path: "#",
    icon: "fas fa-desktop",
    title: "Validasi",
    is_show: false,
    children: [
      { path: "/validasi-jual-beli", title: "Validasi", is_show: false, },
      { path: "/validasi-hutang", title: "Validasi Hutang", is_show: false, },
      // { path: '/validasi-titipan', title: 'Validasi Titipan' },
    ],
  },

  {
    path: "#",
    icon: "fas fa-folder-open",
    title: "Laporan",
    is_show: false,
    children: [
      {
        path: "#",
        title: "Laporan Barang",
        children: [
          {
            path: "/laporan-terima-barang-supplier",
            title: "Laporan Terima Barang Supplier",
            type: "SEMI_GROSIR_MODULE",
            is_show: false,
          },
          { path: "/laporan-barang-detail", title: "Laporan Barang Detail", is_show: false, },
          { path: "/laporan-tambah-barang", title: "Laporan Tambah Barang", is_show: false, },
          { path: "/laporan-timbang-baki", title: "Laporan Timbang Baki", is_show: false, },
          { path: "/laporan-hancur-barang", title: "Laporan Hancur Barang", is_show: false, },
          { path: "/laporan-pindah-barang", title: "Laporan Pindah Barang", is_show: false, },
          { path: "/laporan-barang-sumary", title: "Laporan Barang Summary", is_show: false, },
          // {
          //   path: '#', title: 'Laporan Terima Barang',
          //   children: [
          //     { path: '/laporan-terima-barang', title: 'Laporan Terima Barang', },
          //     { path: '/laporan-barang-detail', title: 'Laporan Barang Detail', }
          //   ]
          // },
        ],
      },
      {
        path: "/laporan-pesanan",
        title: "Laporan Pesanan",
        is_show: false,
      },
      {
        path: "#",
        title: "Laporan Penjualan",
        is_show: false,
        children: [
          {
            path: "/laporan-penjualan-margin",
            title: "Laporan Penjualan Margin",
            is_show: false,
          },
          { path: "/laporan-titip-uang", title: "Laporan Titip Uang", is_show: false, },
          {
            path: "/laporan-stock-jual",
            title: "Laporan Stock Jual",
            is_show: false,
          },
          {
            path: "/laporan-transaksi-penjualan",
            title: "Laporan Transaksi Penjualan",
            is_show: false,
          },
          {
            path: "/laporan-penjualan-marketplace",
            title: "Laporan Penjualan Marketplace",
            is_show: false,
          },
          {
            path: "/laporan-barang-tukar",
            title: "Laporan Barang Tukar",
            is_show: false,
          },
          {
            path: "/laporan-batal-penjualan",
            title: "Laporan Batal Penjualan",
            is_show: false,
          },

          {
            path: "/laporan-penjualan-sales",
            title: "Laporan Penjualan Sales",
            is_show: false,
          },


        ],
      },
      {
        path: "#",
        title: "Laporan Pembelian",
        is_show: false,
        children: [
          {
            path: "/laporan-transaksi-pembelian",
            title: "Laporan Transaksi Pembelian",
            is_show: false,
          },
          {
            path: "/laporan-transaksi-pembelian-history",
            title: "Laporan History Transaksi Pembelian",
            is_show: false,
          },
          {
            path: "/laporan-batal-pembelian",
            title: "Laporan Batal Pembelian",
            is_show: false,
          },
          {
            path: "/laporan-pembelian-sales",
            title: "Laporan Pembelian Sales",
            is_show: false,
          },
        ],
      },
      {
        path: "#",
        title: "Laporan Service",
        is_show: false,
        children: [
          { path: "/laporan-services", title: "Laporan Transaksi Service", is_show: false, },
          { path: "/laporan-batal-service", title: "Laporan Batal Service", is_show: false, },
          { path: "/laporan-service-masuk", title: "Laporan Service (Masuk)", is_show: false, },
          {
            path: "/laporan-service-selesai",
            title: "Laporan Service (Selesai)",
            is_show: false,
          },
          { path: "/laporan-service-ambil", title: "Laporan Service (Ambil)", is_show: false, },
        ],
      },

      {
        path: "#",
        title: "Laporan Hutang",
        is_show: false,
        children: [
          {
            path: "/laporan-transaksi-hutang",
            title: "Laporan Transaksi Hutang",
            is_show: false,
          },
          { path: "/laporan-stock-hutang", title: "Laporan Stock Hutang", is_show: false, },
          { path: "/laporan-hutang-lunas", title: "Laporan Hutang Lunas", is_show: false, },
          { path: "/laporan-batal-hutang", title: "Laporan Batal Hutang", is_show: false, },
        ],
      },
      {
        path: "#",
        title: "Laporan Keuangan",
        is_show: false,
        children: [
          {
            path: "/laporan-transaksi-keuangan-cash",
            title: "Laporan Transaksi Keuangan Cash",
            is_show: false,
          },
          {
            path: "/laporan-keuangan-non-cash",
            title: "Laporan Keuangan Non Cash",
            is_show: false,
          },
        ],
      },
      // {
      //   path: "#",
      //   title: "Laporan Berlian",
      //   type: "BERLIAN_MODULE",
      //   children: [
      //     {
      //       path: "/laporan-buku-berlian",
      //       title: "Laporan Buku Berlian",
      //       type: "BERLIAN_MODULE",
      //     },
      //   ],
      // },
      // {
      //   path: "#",
      //   title: "Laporan Member",
      //   children: [
      //     { path: "/laporan-daftar-member", title: "Laporan Daftar Member" },
      //   ],
      // },

      {
        path: "/laporan-otorisasi",
        title: "Laporan Otorisasi",
        is_show: false,
      },
      {
        path: "/laporan-eod",
        title: "Laporan EOD",
        is_show: false,
      },
      // {
      //   path: "/laporan-authorization",
      //   title: "Laporan Otorisasi",
      // },
    ],
  },
  {
    path: "#",
    icon: "fas fa-users",
    title: "Manage Users",
    is_show: false,
    children: [
      { path: "/data-users", title: "Data Users", is_show: false, },
      { path: "/hak-akses-users", title: "Hak Akses Users", is_show: false, },
    ],
  },
  {
    path: "#",
    icon: "fas fa-cog",
    title: "Utility",
    is_show: false,
    children: [
      { path: "/settingsystem", title: "Setting System", is_show: false, },
      { path: "/cetak-barcode", title: "Cetak Barcode", is_show: false, },
    ],
  },
  {
    path: "#",
    icon: "fas fa-business-time",
    title: "Stock Opname",
    is_show: false,
    children: [
      { path: "/stock-opname", title: "Stock Opname Barang", is_show: false, },
      {
        path: "/stock-opname-penerimaan",
        title: "Stock Opname Penerimaan Barang",
        is_show: false,
      },
    ],
  },
];

if (getItem('userdata')?.kode_toko === "MLT" || getItem('userdata').kode_toko === "NQC") {
  let rekaplaporan = {
    path: "/laporan-penjualan-rekap",
    title: "Laporan Penjualan Rekap",
    is_show: false
  }
  Menu[12].children[2].children.push(rekaplaporan)
}

export default Menu;
