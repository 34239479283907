import React, {
  Component, useState, useEffect, lazy, Suspense,
  useContext,
} from "react";

import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody, PanelContent } from "../panel/panel.jsx";
import {
  hideModal,
  isLoading,
  showModal,
  isEdit,
  simpanDataTmp,
} from "../../actions/datamaster_action";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { Modal, ModalBody, Progress } from "reactstrap";
import Swal from "sweetalert2";
import { ToastNotification } from "./notification.jsx";
import { reset } from "redux-form";
import { deleteData, getDataNoTimeOut2, postData, putDataNoTImeOut, putData, getData2, postData2, getData, getDataNoTimeOut, postDataNoTImeOut, postDataNoTImeOut2, getDataParams, } from "../../config/axios.jsx";
import Tabel from "./tabel.jsx";
import ModalGlobal from "./ModalGlobal.jsx";
import { ReanderField, HiidenFiled, ReanderSelect, SkeletonLoading, InputDate, ReanderCheckBox } from "./field";
import { v4 as uuidv4 } from "uuid";
import { ErrorHandling, convertDate, convertBase64, getItem, setItem, localStoragedecryp, localStorageencryp, formatDate, getTglSystem, getToday, getUserdata, setTitle, capitalize } from "./function.jsx";
import { Gramasi, NumberOnly, } from "../../Validasi/normalize";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import imgfound from "../../assets/img/temp.jpg";
import BootstrapTable from "react-bootstrap-table-next";
import Camera from "react-html5-camera-photo";
import * as moment from "moment-timezone";
import { createNumberMask } from "redux-form-input-masks";
import DualListBox from "react-dual-listbox";
import Loading from 'react-fullscreen-loading';
import * as XLSX from "xlsx";
import { ListTokoKresno } from "./ListDataToko.jsx";
import { selectorMaster } from "../../selector";
import FilterLaporanTgl from "./FilterLaporanTgl.jsx";
import { useSelector } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { io } from "socket.io-client";
import routes from "../../config/page-route.jsx";

const server = process.env.REACT_APP_BACKEND_URL;
const url = process.env.REACT_APP_URL;

export {
  capitalize,
  url,
  setTitle,
  server,
  routes,
  io,
  React,
  Route, withRouter,
  XLSX,
  ListTokoKresno,
  convertDate,
  getToday,
  lazy,
  Gramasi,
  Loading,
  PanelBody,
  putDataNoTImeOut,
  Suspense,
  useContext,
  getDataNoTimeOut,
  getDataNoTimeOut2,
  postDataNoTImeOut,
  postDataNoTImeOut2,
  getData2,
  postData2,
  DualListBox,
  formValueSelector,
  ReanderCheckBox,
  NumberOnly,
  localStorageencryp,
  InputDate,
  getTglSystem,
  localStoragedecryp,
  Modal, ModalBody, Progress,
  imgfound,
  formatDate,
  moment,
  createNumberMask,
  Camera,
  convertBase64,
  ToolkitProvider,
  SkeletonLoading,
  Skeleton,
  BootstrapTable,
  useState,
  useEffect,
  ErrorHandling,
  uuidv4,
  setItem,
  getItem,
  isLoading,
  showModal,
  isEdit,
  ToastNotification,
  Swal,
  reset,
  Link,
  PanelHeader,
  Component,
  Panel,
  ModalGlobal,
  Tabel,
  connect,
  Field,
  hideModal,
  reduxForm,
  getData,
  HiidenFiled,
  ReanderField,
  ReanderSelect,
  deleteData,
  postData,
  putData,
  selectorMaster,
  FilterLaporanTgl,
  getDataParams,
  simpanDataTmp,
  PanelContent,
  useDispatch,
  useSelector,
  getUserdata,

};
